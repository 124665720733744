import React, { useEffect, useState } from 'react';
import {
  BottomStyled,
  BottomTextStyled,
  IconBlockStyled,
  IconsStyled,
  MiddleBlockStyled,
  MiddleTextBlockStyled,
  MiddleTitleStyled,
  TopBlockStyled,
  LinkStyled,
  StoreItem,
  StoresWrapper,
} from './styled';
import { ReactComponent as TwitterIcon } from 'src/assets/icons/twitter.svg';
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram.svg';
import { ReactComponent as DiscordIcon } from 'src/assets/icons/discord.svg';
import { ReactComponent as LinkedInIcon } from 'src/assets/icons/linkedIn.svg';
import { ReactComponent as FacebookIcon } from 'src/assets/icons/facebook.svg';
import { ReactComponent as RedditIcon } from 'src/assets/icons/reddit.svg';
import { ReactComponent as AppStoreIcon } from 'src/assets/icons/app-store.svg';
import { ReactComponent as PlayMarketIcon } from 'src/assets/icons/play-store.svg';
import { ReactComponent as ApkIcon } from 'src/assets/icons/apk.svg';
import { Container } from 'src/components/Container/styled';
import { ContentLineStyled } from 'src/pages/Main/components/Documentation/styled';
import { useNavigate } from 'react-router-dom';

export const Footer = ({ isMain }) => {
  const [elementId, setElementId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (elementId) {
      navigate('/', { state: { elementId } });
    }

    if (!isMain) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    return () => setElementId('');
  }, [elementId, navigate]);
  return (
    <Container>
      <TopBlockStyled>
        <IconBlockStyled>
          <ContentLineStyled />
          <IconsStyled>
            <LinkStyled href="https://twitter.com/HaqabiHQ">
              <TwitterIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://t.me/haqabi">
              <TelegramIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://discord.gg/kXeefuKPmQ">
              <DiscordIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://www.facebook.com/haqabi.wallet">
              <FacebookIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://www.linkedin.com/company/haqabi/">
              <LinkedInIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
            <LinkStyled href="https://www.reddit.com/r/haqabi/">
              <RedditIcon
                width={'13.867vw'}
                height={'13.867vw'}
                style={{
                  cursor: 'pointer',
                  color: 'transparent',
                }}
              />
            </LinkStyled>
          </IconsStyled>
        </IconBlockStyled>
        <ContentLineStyled />
      </TopBlockStyled>
      <MiddleBlockStyled>
        <MiddleTextBlockStyled>
          <MiddleTitleStyled
            $primary={true}
            onClick={() => setElementId('Bonus')}>
            Get Bonus
          </MiddleTitleStyled>
          <MiddleTitleStyled onClick={() => setElementId('Features')}>
            Features
          </MiddleTitleStyled>
          <MiddleTitleStyled onClick={() => setElementId('Comparison')}>
            Comparison
          </MiddleTitleStyled>
          <MiddleTitleStyled onClick={() => setElementId('Timeline')}>
            Timeline
          </MiddleTitleStyled>
          <MiddleTitleStyled onClick={() => setElementId('AboutUs')}>
            About us
          </MiddleTitleStyled>
          <MiddleTitleStyled onClick={() => setElementId('Education')}>
            Education
          </MiddleTitleStyled>
          {/* <MiddleTitleStyled onClick={() => setElementId('Documentation')}>
            Documentation
          </MiddleTitleStyled> */}
        </MiddleTextBlockStyled>
      </MiddleBlockStyled>
      <BottomStyled>
        <StoresWrapper>
          <a
            href="https://apps.apple.com/app/haqabi-wallet/id6477534740"
            target="_blank"
            rel="noreferrer">
            <AppStoreIcon width={'6.4vw'} height={'6.4vw'} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.haqabi.mobilewallet"
            target="_blank"
            rel="noreferrer">
            <PlayMarketIcon width={'6.4vw'} height={'6.4vw'} />
          </a>
          <ApkIcon
            width={'6.4vw'}
            height={'6.4vw'}
            onClick={() => navigate('/download-apk')}
          />
        </StoresWrapper>
        <BottomTextStyled>support@haqabi.com</BottomTextStyled>
        <BottomTextStyled>
          Copyright © 2024. All rights reserved.
        </BottomTextStyled>
      </BottomStyled>
    </Container>
  );
};
