import styled from 'styled-components';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';
import { ReactComponent as MedalGold } from 'src/assets/icons/bonus-game/medal-gold.svg';
import { ReactComponent as MedalSilver } from 'src/assets/icons/bonus-game/medal-silver.svg';
import { ReactComponent as MedalBronze } from 'src/assets/icons/bonus-game/medal-bronze.svg';
import { ReactComponent as MedalRegular } from 'src/assets/icons/bonus-game/medal.svg';
import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { useEffect, useState } from 'react';
import { bonusGameApi } from 'src/api/bonus-game';
import { useInView } from 'react-intersection-observer';

const LeaderBoardWrapper = styled.div`
  margin-top: 14px;
  padding: 0 20px;
`;

const Label = styled.p`
  font-size: 14px;
  font-family: Public-Sans, sans-serif;
  color: #dde4ee;
  font-weight: 600;
`;

const PlayerRowItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;
  border-radius: 12px;
  background: #212730;
`;

const MedalWrapper = styled.div`
  position: relative;
`;

const BonusAmount = styled.div`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const GamerName = styled.p`
  text-overflow: ellipsis;
  font-family: Public-Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

const MedalPosition = styled.p`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-family: Public-Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
`;

const medalIcons = [
  <MedalGold key={'gold'} />,
  <MedalSilver key={'silver'} />,
  <MedalBronze key={'bronze'} />,
];

const getMedalIcon = position => {
  if (position > 3) {
    return <MedalRegular />;
  }
  return medalIcons[position - 1];
};

export const LeaderBoard = () => {
  const [data, setData] = useState([]);
  const [myPoistion, seyMyPosition] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const getStatReq = bonusGameApi.getStat();
        const leaderBoardReq = bonusGameApi.getLeaderBoard({
          pagination: {
            take: 25,
          },
        });

        const [getStatResponse, leaderBoardResponse] = await Promise.all([
          getStatReq,
          leaderBoardReq,
        ]);

        seyMyPosition(getStatResponse.result);
        setData(leaderBoardResponse.result);
      } catch (e) {
        console.log('err', e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const fetchMoreData = async () => {
    const currentItemsLength = data.length;
    const leaderBoardResponse = await bonusGameApi.getLeaderBoard({
      pagination: {
        take: 25,
        skip: currentItemsLength,
      },
    });
    setData(prev => [...prev, ...leaderBoardResponse.result]);
  };

  const loadMoreData = inView => {
    if (inView) {
      fetchMoreData();
    }
  };

  const { ref } = useInView({
    threshold: 0,
    initialInView: false,
    onChange: loadMoreData,
    triggerOnce: true,
  });

  return (
    <LeaderBoardWrapper>
      <VerticalView style={{ marginBottom: 8, gap: 8 }}>
        <Label>My position</Label>
        {!loading && <PlayerRowItem gamer={myPoistion} />}
      </VerticalView>
      <VerticalView style={{ gap: 8 }}>
        <Label>Top players</Label>

        {data.map((gamer, idx) => {
          const isLastItem = idx === data.length - 1;
          return (
            <div key={gamer.position} ref={isLastItem ? ref : null}>
              {' '}
              <PlayerRowItem gamer={gamer} />
            </div>
          );
        })}
      </VerticalView>
    </LeaderBoardWrapper>
  );
};

const PlayerRowItem = ({ gamer }) => {
  return (
    <PlayerRowItemWrapper>
      <HorizontalView style={{ gap: 12 }}>
        <MedalWrapper>
          {getMedalIcon(gamer.position)}

          <MedalPosition
            style={{
              color: Number(gamer.position) > 2 ? '#fff' : 'rgba(0, 0, 0, 0.8)',
            }}>
            {Number(gamer.position)}
          </MedalPosition>
        </MedalWrapper>
        <GamerName>{gamer.bonusTreeName}</GamerName>
      </HorizontalView>
      <HorizontalView style={{ gap: 4 }}>
        <CoinIcon width={12} height={12} />
        <BonusAmount>{gamer.rating}</BonusAmount>
      </HorizontalView>
    </PlayerRowItemWrapper>
  );
};
