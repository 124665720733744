import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';
import { ReactComponent as TShirtIcon } from 'src/assets/icons/bonus-game/t-shirt.svg';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';

const BonusPlacePrizeWrapper = styled(VerticalView)`
  position: relative;
  border-radius: 8px;
  border: ${({ isCurrentDay }) =>
    isCurrentDay ? '1px solid #7EF424 ' : 'none'};
  background: #21252e;
  width: 20.513vw;
  height: 20.513vw;
`;
const BonusInfo = styled(VerticalView)`
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 4px;
`;

const BonusAmount = styled.p`
  font-size: 16px;
  color: #fffefd;
  font-family: Public-Sans, sans-serif;
`;

const BonusPlaces = styled(VerticalView)`
  border-radius: 0px 0px 6px 6px;
  background: ${({ isCurrentDay }) =>
    isCurrentDay
      ? 'linear-gradient(90deg, #7EF424 0%, #C8EB01 100%)'
      : 'linear-gradient(90deg, #FEBF07 0%, #EB9201 100%)'};
  height: 16.25px;
  display: grid;
`;

const BonusPlaceText = styled.p`
  color: #05143c;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 12px;
  font-weight: 500;
  align-self: center;
  justify-self: center;
`;

export const DailyRewardItem = ({ day, coins, isCurrentDay }) => {
  return (
    <BonusPlacePrizeWrapper isCurrentDay={isCurrentDay}>
      <BonusInfo>
        <CoinIcon />

        <BonusAmount>{coins}</BonusAmount>
      </BonusInfo>
      <BonusPlaces isCurrentDay={isCurrentDay}>
        <BonusPlaceText>{day}</BonusPlaceText>
      </BonusPlaces>
    </BonusPlacePrizeWrapper>
  );
};
