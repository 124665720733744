import { Route, Routes } from 'react-router-dom';
import { GamePage } from './screens/GamePage';
import { WelcomePage } from './screens/WelcomePage';
import { useEffect, useLayoutEffect, useState } from 'react';
import { SeasonOne } from './screens/SeasonOne';
import { SeasonInfo } from './screens/SeasonInfo';
import { DailyRewardsPage } from './screens/DailyRewardsPage';
import { LeaderBoardPage } from './screens/LeaderBoardPage';
import styled from 'styled-components';
import { bonusGameApi } from 'src/api/bonus-game';
import { NotDepositedModal } from './screens/GamePage/components/NotDepositedModal';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
  }
  margin: 20px 0;
`;

const rootPath = '/bonus-game';

export const BonusGameRoutes = () => {
  const [isGameAvailable, setGameAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [appKey, setAppKey] = useState(0);
  const [randomBonusModalVisible, setRandomBonusModalVisible] = useState();

  useEffect(() => {
    document.body.style.background = 'none';
    document.body.style.overflow = 'hidden';

    const bonusGameElem = document.getElementById('root');
    if (bonusGameElem) {
      bonusGameElem.style.overflowY = 'auto';
      bonusGameElem.style.height = '101vh';
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const isAvailableResponse = await bonusGameApi.checkAvailability();
        const isAvailable = isAvailableResponse.result;
        setGameAvailable(isAvailable);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isGameAvailable) {
        try {
          const getStatRes = await bonusGameApi.getStat();
          setRandomBonusModalVisible(getStatRes.result.randomRewardAvailable);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [isGameAvailable]);

  if (loading) return null;

  if (!isGameAvailable) {
    return <NotDepositedModal />;
  }

  return (
    <Layout id="bonus-game-layout">
      <Routes>
        <Route
          exact
          path={rootPath}
          element={
            <GamePage
              key={appKey}
              setAppKey={setAppKey}
              randomBonusModalVisible={randomBonusModalVisible}
              setRandomBonusModalVisible={setRandomBonusModalVisible}
            />
          }
        />
        <Route exact path={`${rootPath}/welcome`} element={<WelcomePage />} />
        <Route
          exact
          path={`${rootPath}/season-info`}
          element={<SeasonInfo />}
        />
        <Route exact path={`${rootPath}/season-one`} element={<SeasonOne />} />
        <Route
          path={`${rootPath}/daily-rewards`}
          element={<DailyRewardsPage />}
        />
        <Route
          path={`${rootPath}/top-leaderboard`}
          element={<LeaderBoardPage />}
        />
      </Routes>
    </Layout>
  );
};
