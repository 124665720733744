import { useMemo, useRef, useState } from 'react';
import { CoinWrapper, TreeGameWrapper } from './styled';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';
import { ReactComponent as StarIcon } from 'src/assets/icons/bonus-game/star-2.svg';
import { bonusGameApi } from 'src/api/bonus-game';
import styled from 'styled-components';

import coinImagePath from 'src/assets/img/bonus-game/Coin.png';

const positions = [
  { top: '38vw', left: '16vw' },
  { top: '30vw', left: '28vw' },
  { top: '18vw', left: '20vw' },
  { top: '20vw', left: '38vw' },
  { top: '32vw', left: '40vw' },
  { top: '8vw', left: '32vw' },
  { top: '12vw', left: '40vw' },
  { top: '12vw', left: '50vw' },
  { top: '24vw', left: '56vw' },
  { top: '36vw', left: '56vw' },
];

const Image = styled.img`
  width: 6.14vw;
  height: 6.14vw;
`;

const shuffleCoins = coinsLeft => {
  const availablePositions = [...positions];

  const shuffledCoins = [];
  for (let i = 0; i < Number(coinsLeft); i++) {
    const randomCoinPos = Math.floor(Math.random() * availablePositions.length);

    shuffledCoins.push({
      ...availablePositions[randomCoinPos],
      collected: false,
    });

    availablePositions.splice(randomCoinPos, 1);
  }

  while (availablePositions.length) {
    const randomCoinPos = Math.floor(Math.random() * availablePositions.length);

    shuffledCoins.push({
      ...availablePositions[randomCoinPos],
      collected: true,
    });

    availablePositions.splice(randomCoinPos, 1);
  }

  return shuffledCoins;
};

export const TreeGame = ({
  setEndNumber,
  coinsLeft,
  balance,
  setDailyBonusKey,
}) => {
  const parrentRef = useRef(null);

  const shuffledCoins = useMemo(() => shuffleCoins(coinsLeft ?? 10), []);

  return (
    <TreeGameWrapper ref={parrentRef} animate={true}>
      {shuffledCoins.map((pos, idx) => (
        <Coin
          key={idx}
          {...pos}
          parrentRef={parrentRef}
          setEndNumber={setEndNumber}
          balance={balance}
          coinCollected={pos.collected}
          setDailyBonusKey={setDailyBonusKey}
          coinsLeft={coinsLeft}
        />
      ))}
    </TreeGameWrapper>
  );
};

const Coin = ({
  top,
  left,
  parrentRef,
  setEndNumber,
  coinCollected,
  coinsLeft,
  setDailyBonusKey,
}) => {
  const [animate, setAnimate] = useState(false);
  const [isStarVisible, setIsStarVisible] = useState(coinCollected);
  const coinRef = useRef(null);

  const distanceToTop = useMemo(() => {
    if (parrentRef.current && coinRef.current) {
      const parentRect = parrentRef.current.getBoundingClientRect();
      const childRect = coinRef.current.getBoundingClientRect();
      return childRect.top - parentRect.top;
    }
    return 0;
  }, [parrentRef, coinRef, animate]);

  const handleCoinClick = async () => {
    const claimRewardsRes = await bonusGameApi.claimRewards();
    setAnimate(true);
    setEndNumber(prev => prev + Number(claimRewardsRes.result.amount));
    if (coinsLeft === '10') {
      setDailyBonusKey(prev => prev + 1);
    }
    setTimeout(() => {
      setAnimate(false);
      setIsStarVisible(true);
    }, 1000);
  };

  return (
    <CoinWrapper
      top={top}
      left={left}
      ref={coinRef}
      animate={animate}
      distanceTopSize={distanceToTop}>
      {isStarVisible ? (
        <StarIcon
          width="6.41vw"
          height="6.41vw"
          style={{
            backgroundColor: 'transparent',
            userSelect: 'none',
            outline: 'none',
          }}
        />
      ) : (
        <Image src={coinImagePath} onClick={handleCoinClick} />
      )}
    </CoinWrapper>
  );
};
