import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { BonusPlacePrize } from '../BonusPlacePrize';
import styled from 'styled-components';

const Star = styled.p`
  position: absolute;
  top: -9px;
  right: -8px;
  font-family: 'Public-Sans', sans-serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.22px;
`;

export const BonusPlacePrizes = () => {
  return (
    <VerticalView style={{ alignItems: 'center', gap: 16 }}>
      <HorizontalView
        style={{
          position: 'relative',
          justifyContent: 'center',
          width: '100%',
        }}>
        <BonusPlacePrize place={'1 place'} row={1} prize={'$300'} />
        {/* <Star>⭐️</Star> */}
      </HorizontalView>
      <HorizontalView
        style={{ gap: 8, width: '100%', justifyContent: 'center' }}>
        <BonusPlacePrize place={'2-5'} row={2} prize={'$200'} />
        <BonusPlacePrize place={'6-10'} row={2} prize={'$100'} />
      </HorizontalView>
      <HorizontalView
        style={{ gap: 8, width: '100%', justifyContent: 'center' }}>
        <BonusPlacePrize place={'11-25'} row={3} prize={'$30'} />
        <BonusPlacePrize place={'26-50'} row={3} prize={'$15'} />
        <BonusPlacePrize place={'51-100'} row={3} prize={'$10'} />
        <BonusPlacePrize place={'101-150'} row={3} prize={'$5'} />
      </HorizontalView>
    </VerticalView>
  );
};
