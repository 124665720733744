import styled from 'styled-components';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';
import { ReactComponent as MedalGold } from 'src/assets/icons/bonus-game/medal-gold.svg';
import { ReactComponent as MedalSilver } from 'src/assets/icons/bonus-game/medal-silver.svg';
import { ReactComponent as MedalBronze } from 'src/assets/icons/bonus-game/medal-bronze.svg';
import { ReactComponent as MedalRegular } from 'src/assets/icons/bonus-game/medal.svg';
import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { Button } from 'src/pages/BonusGame/components/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { bonusGameApi } from 'src/api/bonus-game';
const LeaderBoardWrapper = styled.div`
  margin-top: 14px;
  padding-bottom: 20px;
`;

const Label = styled.p`
  font-size: 14px;
  font-family: Public-Sans, sans-serif;
  color: #dde4ee;
  font-weight: 600;
`;

const PlayerRowItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;
  border-radius: 12px;
  background: #212730;
`;

const MedalWrapper = styled.div`
  position: relative;
`;

const BonusAmount = styled.div`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const GamerName = styled.p`
  text-overflow: ellipsis;
  font-family: Public-Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

const MedalPosition = styled.p`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  font-family: Public-Sans, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
`;

const medalIcons = [
  <MedalGold key={'gold'} />,
  <MedalSilver key={'silver'} />,
  <MedalBronze key={'bronze'} />,
];

const getMedalIcon = position => {
  if (position > 3) {
    return <MedalRegular />;
  }
  return medalIcons[position - 1];
};

const maxListItems = 25;

export const LeaderBoard = ({ gameStat }) => {
  const navigate = useNavigate();
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [currentUserPos, setCurrentUserPos] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const bonusTreeName = gameStat.bonusTreeName;

        const leaderBoardRes = await bonusGameApi.getLeaderBoard({
          pagination: { take: 25 },
        });

        setLeaderBoard(leaderBoardRes.result);
        setCurrentUserPos({ ...gameStat });
      } catch (e) {
        console.error('err', e);
      } finally {
        setLoading(false);
      }
    })();
  }, [gameStat]);

  if (loading) {
    return null;
  }

  return (
    <LeaderBoardWrapper>
      <VerticalView style={{ marginBottom: 8, gap: 8 }}>
        <Label>My position</Label>

        <PlayerRowItem player={currentUserPos} />
      </VerticalView>
      <VerticalView style={{ gap: 8 }}>
        <Label>Top players</Label>
        <VerticalView style={{ gap: '12px' }}>
          <VerticalView style={{ gap: '12px' }}>
            {leaderBoard.slice(0, maxListItems).map(player => (
              <PlayerRowItem key={player.position} player={player} />
            ))}
          </VerticalView>

          <Button
            title="Show all"
            onClick={() => navigate('/bonus-game/top-leaderboard')}
          />
        </VerticalView>
      </VerticalView>
    </LeaderBoardWrapper>
  );
};

const PlayerRowItem = ({ player }) => {
  return (
    <PlayerRowItemWrapper key={player.position}>
      <HorizontalView style={{ gap: 12 }}>
        <MedalWrapper>
          {getMedalIcon(Number(player.position))}

          <MedalPosition
            style={{
              color:
                Number(player.position) > 2 ? '#fff' : 'rgba(0, 0, 0, 0.8)',
            }}>
            {Number(player.position)}
          </MedalPosition>
        </MedalWrapper>
        <GamerName>{player.bonusTreeName}</GamerName>
      </HorizontalView>
      <HorizontalView style={{ gap: 4 }}>
        <CoinIcon width={12} height={12} />
        <BonusAmount>{player.rating}</BonusAmount>
      </HorizontalView>
    </PlayerRowItemWrapper>
  );
};
