import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import images from './utils/images';
import messages from './utils/messages';
import s from './index.module.scss';

import {
  TELEGRAM_URL,
  TWITTER_URL,
} from '../../components/articlesList/util/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Text } from '../../components';
import Image from '../../components/Image';
import { FooterDesktop } from 'src/pages/Main/components/FooterDesktop';
import { Footer } from 'src/pages/Main/components/Footer';
import { useDeviceType } from 'src/hooks/useDeviceType';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { formatDate } from '../../components/articlesList/util/formatDate';
import { fetchData } from 'src/butter-client';
import { Header } from 'src/pages/Main/components/Header';
import { HeaderDesktop } from 'src/pages/Main/components/HeaderDesktop';
import Breadcrumbs from '../../components/Breadcrumbs';
import styled from 'styled-components';

const SOCIAL_LINKS = {
  facebook: '',
  twitter: TWITTER_URL,
  telegram: TELEGRAM_URL,
};

const Wrapper = styled.div`
  background: #10151b;
`;

const Article = () => {
  const [article, setArticle] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const navigate = useNavigate();
  const { articleId } = useParams();

  useEffect(() => {
    const fetchArticle = async () => {
      const article = await fetchData('posts/' + articleId);
      const posts = await fetchData('posts', {
        limit: 3,
        category_slug: 'haqabi',
      });
      const relatedPosts = posts?.data
        ? posts.data.map(article => ({
            featured_image: article.featured_image,
            title: article.title,
            url: article.url,
          }))
        : [];
      setArticle(article.data);
      setRelatedPosts(relatedPosts);
    };
    fetchArticle();
  }, [articleId]);

  const { isMobile } = useDeviceType();

  const { md, lg } = useBreakpoint();

  const backHandler = useCallback(() => {
    navigate('/education');
  }, []);

  const scrollToTop = useCallback(() => {
    window?.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  const onClickShareLink = useCallback(
    social => {
      window?.open(
        `${SOCIAL_LINKS[social]}?url=${window?.location.href}&text=${article?.title}`,
        '_blank',
      );
    },
    [article],
  );

  const handleChangeTag = ({ slug: tag }) => {
    const newParams = new URLSearchParams();
    newParams.set('tag_slug', tag);

    const newUrl = `/education?${newParams.toString()}`;

    navigate(newUrl);
  };

  return (
    <Wrapper>
      <div className="pt-80">{isMobile ? <Header /> : <HeaderDesktop />}</div>
      <div
        className={cx(s.container, 'flex flex-col items-center px-16 pt-80', {
          'px-32': md,
          'px-120': lg,
        })}>
        <div
          className={cx('flwx-grow-1 w-full', {
            'w-80': lg,
          })}>
          <Breadcrumbs className="py-12">
            <Link to="/education">
              <Text
                size="s14"
                color="neutral-5"
                message={messages.education.en}
                className={cx(s.breadcrumb, 'transition')}
              />
            </Link>
            <Link to={`/education/${article?.slug}`}>
              <Text
                size="s14"
                message={article?.title}
                className={cx(s.breadcrumb, 'transition')}
              />
            </Link>
          </Breadcrumbs>
          <div
            className={cx(
              'flex items-start grid-gap-32',
              lg ? 'flex-row' : 'flex-col',
            )}>
            <div className="flex-basis-70">
              {article?.body ? (
                <>
                  <Text
                    className="mt-32"
                    size="s24"
                    color="neutral-1"
                    message={article?.title}
                  />
                  <div className="flex items-center mt-24">
                    <Text
                      color="neutral-4"
                      size="l16"
                      message={formatDate(new Date(article?.published))}
                    />
                    <Image src={images.clockIcon} className="ml-16" size={18} />
                    <Text
                      color="neutral-4"
                      size="l16"
                      className="ml-8"
                      message="7 min"
                    />
                  </div>
                  {article?.tags.length === 0 ? null : (
                    <div className="flex items-center grid-gap-16 mt-24">
                      {article?.tags.map(item => (
                        <Button
                          onClick={() => handleChangeTag(item)}
                          color="primary"
                          bg="neutral"
                          key={item.name}
                          message={item.name}
                        />
                      ))}
                    </div>
                  )}
                  <div
                    className={cx(s.cmsArticle, 'mt-32')}
                    dangerouslySetInnerHTML={{ __html: article?.body }}
                  />
                </>
              ) : (
                <Text
                  size="m26"
                  color="primary-5"
                  className="px-60"
                  message={messages.articleOnModeration}
                />
              )}
              <div className="flex items-center justify-between mt-32">
                <Button
                  color="primary"
                  border="primary"
                  startAdornment={
                    <Image src={images.arrowIcon} size={20} color="primary-5" />
                  }
                  message={messages.backToEducation.en}
                  onClick={backHandler}
                />
                <Button
                  border="neutral"
                  className={cx(
                    s.scrollUp,
                    'flex items-center justify-center no-shrink radius-round flex-grow-0',
                  )}
                  onClick={scrollToTop}>
                  <Image src={images.chevronUpIcon} size={24} />
                </Button>
              </div>
            </div>
            <div
              className={cx(s.rightPart, 'sticky w-full p-16', {
                'flex-basis-30': md,
              })}>
              <Text size="m20" color="neutral-1" message={messages.share.en} />
              <div className="flex items-center grid-gap-24 mt-24 mb-48">
                <button
                  onClick={() => {
                    onClickShareLink('twitter');
                  }}>
                  <Image src={images.twitterIcon} size={28} />
                </button>
                <button
                  onClick={() => {
                    onClickShareLink('telegram');
                  }}>
                  <Image src={images.telegramIcon} size={28} />
                </button>
              </div>
              <Text
                size="m20"
                color="neutral-1"
                message={messages.relatedPosts}
              />
              <div>
                {relatedPosts.map((post, index) => (
                  <Link
                    className={cx(
                      s.post,
                      'mt-24 radius-12 border border-color-neutral-7 overflow-hidden flex flex-col',
                    )}
                    key={index}
                    to={`/education${post.url}`}>
                    <div
                      className={cx(s.preview, 'bg-cover pointer')}
                      style={{ backgroundImage: `url(${post.featured_image})` }}
                    />
                    <Text
                      size="m20"
                      color="neutral-1"
                      className="p-16"
                      message={post.title}
                    />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-80">{isMobile ? <Footer /> : <FooterDesktop />}</div>
    </Wrapper>
  );
};

export default React.memo(Article);
