import styled from 'styled-components';
import { Container } from '../../../../components/Container/styled';
import SwapsImage from 'src/assets/img/swaps.webp';

export const SwapsDesktop = () => {
  return (
    <Container style={{ marginBottom: '8.333vw' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <TitleStyled>
          <span style={{ color: 'var(--color-primary-5)' }}>Deposit, send</span>{' '}
          and <span style={{ color: 'var(--color-primary-5)' }}>swap</span>{' '}
          popular tokens
        </TitleStyled>
        <SwapsBlockWrapper>
          <SwapsImageStyled src={SwapsImage} />
        </SwapsBlockWrapper>
      </div>
    </Container>
  );
};

const TitleStyled = styled.p`
  font-size: Inter, sans-serif;
  font-weight: 700;
  font-size: 2.917vw;
  text-align: center;
  margin-bottom: 2.778vw;
`;

const SwapsBlockWrapper = styled.div`
  background: linear-gradient(
    104.69deg,
    rgba(255, 255, 255, 0.05) 15.53%,
    rgba(255, 255, 255, 0) 87.71%
  );
  border: solid rgba(82, 90, 103, 1) 0.069vw;
  border-radius: 0.833vw;
  padding: 0;
`;

const SwapsImageStyled = styled.img`
  width: 100%;
`;
