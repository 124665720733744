import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { Button } from 'src/pages/BonusGame/components/Button';
import styled from 'styled-components';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-menu.svg';
import { bonusGameApi } from 'src/api/bonus-game';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  padding: 0 20px;
`;

const ModalWrapper = styled(VerticalView)`
  position: relative;
  width: 100%;
  padding: 32px 24px;
  border-radius: 12px;
  border: 1px solid #29313e;
  background: #171d25;
`;
const Title = styled.p`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(123deg, #03b8ff 14.45%, #fa52a0 76.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
`;

const GameRule = styled.p`
  color: #fcfcfc;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const ButtonStyled = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
`;
const BonusAmount = styled.p`
  color: #fffefd;
  font-family: Public-Sans, sans-serif;
  font-size: 24px;
  font-weight: 500;
`;

export const RandomBonusModal = ({
  setEndNumber,
  closeModal,
  extraRewardAmount,
}) => {
  async function handleClaimReward() {
    try {
      const claimAdditionalRewardsRes =
        await bonusGameApi.claimAdditionalRewards();
      const amount = claimAdditionalRewardsRes.result.amount;
      setEndNumber(prev => prev + Number(amount));
    } catch (e) {
      console.error(e);
    } finally {
      closeModal();
    }
  }

  return (
    <ModalOverlay>
      <ModalWrapper>
        <ButtonStyled onClick={closeModal}>
          <CloseIcon color="rgba(41, 49, 62, 1)" />
        </ButtonStyled>
        <Title>Congratulations! </Title>
        <p style={{ fontSize: 32, alignSelf: 'center', marginBottom: 20 }}>
          🎉
        </p>
        <GameRule>Collect your extra coins now!</GameRule>
        <HorizontalView
          style={{ gap: 8, alignSelf: 'center', marginBottom: 32 }}>
          <CoinIcon />
          <BonusAmount>{extraRewardAmount}</BonusAmount>
        </HorizontalView>
        <Button
          title={'Claim reward'}
          color={'positive'}
          onClick={handleClaimReward}
        />
      </ModalWrapper>
    </ModalOverlay>
  );
};
