import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DailyBonus } from './components/DailyBonus';
import { GameHeader } from './components/GameHeader';
import { TreeGame } from './components/TreeGame';
import { GameBalance } from './components/GameBalance';
import { DailyRewards } from './components/DailyRewards';
import { LeaderBoard } from './components/LeaderBoard';
import { bonusGameApi } from 'src/api/bonus-game';
import { useNavigate } from 'react-router-dom';
import { RandomBonusModal } from './components/RandomBonusModal';

const PageWrapper = styled.div`
  background-color: #090d12;
  height: 100%;
  padding: 0 20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const GamePage = ({
  setAppKey,
  randomBonusModalVisible,
  setRandomBonusModalVisible,
}) => {
  const navigate = useNavigate();
  const [gameStat, setGameStat] = useState(null);
  const [balance, setBalance] = useState(0);
  const [endNumber, setEndNumber] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [dailyBonusKey, setDailyBonusKey] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const gameStateResponse = await bonusGameApi.getStat();
        setGameStat(gameStateResponse.result);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [endNumber]);

  useEffect(() => {
    if (!gameStat) return;
    if (!gameStat.welcomeBonusClaimed) {
      navigate('/bonus-game/welcome');
    } else {
      setBalance(Number(gameStat.rating));
      setEndNumber(Number(gameStat.rating));
    }
    setLoading(false);
  }, [gameStat]);

  if (isLoading) return null;

  return (
    <PageWrapper>
      <GameHeader gameStat={gameStat} setGameStat={setGameStat} />
      <GameBalance balance={balance} endNumberBalance={endNumber} />
      <DailyBonus
        key={dailyBonusKey}
        coinsLeft={gameStat.coinsLeft}
        setAppKey={setAppKey}
      />
      <TreeGame
        setEndNumber={setEndNumber}
        coinsLeft={gameStat.coinsLeft}
        balance={balance}
        setDailyBonusKey={setDailyBonusKey}
      />
      <DailyRewards daysInARow={gameStat.daysInARow} />
      <LeaderBoard
        gameStat={gameStat}
        bonusTreeName={gameStat?.bonusTreeName}
        endNumber={endNumber}
      />

      {randomBonusModalVisible && (
        <RandomBonusModal
          extraRewardAmount={gameStat.extraRewardAmount}
          setEndNumber={setEndNumber}
          closeModal={() => setRandomBonusModalVisible(false)}
        />
      )}
    </PageWrapper>
  );
};
