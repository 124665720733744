import { useEffect, useState } from 'react';
import {
  EditableInput,
  GameHeaderWrapper,
  Label,
  LabelWrapper,
  QuestionMarkWrapper,
} from './styled';
import { ReactComponent as EditTitleIcon } from 'src/assets/icons/bonus-game/edit-title.svg';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/bonus-game/left-arrow.svg';
import { ReactComponent as QuestionMarkIcon } from 'src/assets/icons/bonus-game/questionmark.svg';
import { HorizontalView } from 'src/components/FlexViews';
import { useNavigate } from 'react-router-dom';
import { bonusGameApi } from 'src/api/bonus-game';

const EditableLabel = ({ gameStat, setGameStat }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    setText(gameStat?.bonusTreeName ?? '');
  }, [gameStat?.bonusTreeName]);

  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = event => {
    setText(event.target.value);
  };

  const handleBlur = async () => {
    setIsEditing(false);
    const changeNameRes = await bonusGameApi.changeName({ name: text });
    if (changeNameRes?.result) {
      setGameStat({ ...gameStat, bonusTreeName: text });
    } else {
      setText(gameStat?.bonusTreeName);
    }
  };

  return (
    <LabelWrapper onClick={handleToggleEdit}>
      {isEditing ? (
        <EditableInput
          type="text"
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <HorizontalView style={{ gap: '8px' }}>
          <Label>{text}</Label>
          <EditTitleIcon />
        </HorizontalView>
      )}
    </LabelWrapper>
  );
};

export const GameHeader = ({ gameStat, setGameStat }) => {
  const navigate = useNavigate();

  function handleGoBack() {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'navigate-back' }),
    );
  }

  return (
    <GameHeaderWrapper>
      <button onClick={handleGoBack}>
        <LeftArrowIcon color="rgba(82, 90, 103, 1)" width={24} height={24} />
      </button>
      <EditableLabel gameStat={gameStat} setGameStat={setGameStat} />
      <QuestionMarkWrapper onClick={() => navigate('/bonus-game/season-one')}>
        <QuestionMarkIcon color="rgba(82, 90, 103, 1)" />
      </QuestionMarkWrapper>
    </GameHeaderWrapper>
  );
};
