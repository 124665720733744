import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';

const BonusRuleRowItemDescription = styled.p`
  color: #fffefd;
  font-family: Public-Sans, sans-serif;
  font-size: 12px;
`;

const BonusRuleRowItemWrapper = styled(VerticalView)`
  border-radius: 8px;
  background: #21252e;
  height: 52px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const BonusRoleItemTitle = styled.p`
  color: #aade16;
  font-family: Public-Sans, sans-serif;
  font-size: 12px;
  font-weight: 500;
`;
export const BonusRuleRowItem = ({ title, icon, description }) => {
  return (
    <BonusRuleRowItemWrapper>
      <BonusRoleItemTitle>{title}</BonusRoleItemTitle>
      <HorizontalView style={{ gap: 8 }}>
        {icon}
        <BonusRuleRowItemDescription>{description}</BonusRuleRowItemDescription>
      </HorizontalView>
    </BonusRuleRowItemWrapper>
  );
};
