import { useEffect, useState } from 'react';
import {
  DailyBonusItemsWrapper,
  DailyBonusItemText,
  DailyBonusItemTitle,
  DailyBonusItemWrapper,
} from './styled';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';
import { bonusGameApi } from 'src/api/bonus-game';

export const DailyBonus = ({ coinsLeft, setAppKey }) => {
  const [rewardData, setRewardData] = useState(null);
  const [targetDate, setTargetDate] = useState(0);
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const fetchRewardData = async () => {
      try {
        const nextReward = await bonusGameApi.checkNextReward();
        setRewardData(nextReward);
      } catch (error) {
        console.error('Failed to fetch reward data:', error);
      }
    };
    fetchRewardData();
  }, []);

  useEffect(() => {
    if (!rewardData) return;
    const unlockTime = new Date(rewardData.unlockNextRewardTime).getTime();
    setTargetDate(unlockTime);
  }, [rewardData]);

  useEffect(() => {
    if (!targetDate) return;

    const timer = setInterval(() => {
      const now = Date.now();
      const difference = Math.max(targetDate - now, 0);

      if (difference <= 0 && Number(coinsLeft) < 10) {
        setAppKey(prev => prev + 1);
      }

      setTimeLeft({
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, coinsLeft]);

  return (
    <DailyBonusItemsWrapper>
      <DailyBonusItemWrapper>
        <DailyBonusItemTitle color="#F79841">Next Reward</DailyBonusItemTitle>
        <DailyBonusItemText>
          {timeLeft.hours}h:{timeLeft.minutes}m
        </DailyBonusItemText>
      </DailyBonusItemWrapper>
      <DailyBonusItemWrapper>
        <DailyBonusItemTitle color="#E25319">Reward Amount</DailyBonusItemTitle>
        <DailyBonusItemText>
          <CoinIcon width={12} height={12} />
          {rewardData?.nextRewardAmount ?? 0}
        </DailyBonusItemText>
      </DailyBonusItemWrapper>
      <DailyBonusItemWrapper
        onClick={() => {
          if (rewardData?.multiplicator === '1') {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: 'show-multiplier-modal',
              }),
            );
          }
        }}>
        <DailyBonusItemTitle color="#199AE2">Multiplier</DailyBonusItemTitle>
        <DailyBonusItemText>
          X{rewardData?.multiplicator ?? 1} ⭐️
        </DailyBonusItemText>
      </DailyBonusItemWrapper>
    </DailyBonusItemsWrapper>
  );
};
