import styled, { css, keyframes } from 'styled-components';
import treeImagePath from 'src/assets/img/bonus-game/tree.webp';

const moveUp = distanceTopSize => keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-${distanceTopSize}px); 
    opacity: 0;
  }
`;

const shake = keyframes`
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-5px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate3d(5px, 0, 0);
  }
`;

const TreeGameWrapper = styled.div`
  width: 78vw;
  height: 80vw;
  position: relative;
  background: center no-repeat url(${treeImagePath});
  background-size: cover;
  margin: 24px auto;
  animation: ${({ animate }) =>
    animate
      ? css`
          ${shake} 1s ease-in-out 1s;
        `
      : 'none'};
`;

const CoinWrapper = styled.div`
  position: absolute;
  background: none;
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  cursor: pointer;
  animation: ${({ animate, distanceTopSize }) =>
    animate
      ? css`
          ${moveUp(distanceTopSize)} 1s forwards
        `
      : 'none'};

  user-select: none;
  background-color: transparent;
  outline: none;
`;

export { CoinWrapper, TreeGameWrapper };
