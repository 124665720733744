import { getMapper, postMapper } from '..';

export const bonusGameApi = {
  checkAvailability: getMapper('checkAvailability'),
  getStat: getMapper('getStat'),
  claimRewards: postMapper('claimRewards'),
  getWelcomeBonus: postMapper('getWelcomeBonus'),
  checkNextReward: getMapper('checkNextReward'),
  changeName: postMapper('changeName'),
  getLeaderBoard: postMapper('getLeaderBoard'),
  resetDate: getMapper('resetDate'),
  claimAdditionalRewards: postMapper('claimAdditionalRewards'),
  getRewardsHistory: getMapper('getRewardsHistory'),
};
