import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/bonus-game/left-arrow.svg';
import { ReactComponent as TShirtIcon } from 'src/assets/icons/bonus-game/t-shirt.svg';
import { ReactComponent as StarIcon } from 'src/assets/icons/bonus-game/star.svg';
import { ReactComponent as DiscordIcon } from 'src/assets/icons/bonus-game/discord.svg';
import { ReactComponent as QuestionMarkIcon } from 'src/assets/icons/bonus-game/questionmark.svg';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BonusRuleRowItem } from './components/BonusRuleRowItem';
import { BonusPlacePrizes } from './components/BonusPlacePrizes';

const PageWrapper = styled(VerticalView)`
  padding: 0 20px;
`;

const ContentWrapper = styled(VerticalView)`
  margin-top: 26px;
`;

const HeaderTitle = styled.p`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  flex: 1;
  margin-left: -12px;
`;

const ContentTitle = styled.p`
  color: #aade16;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 12px;
`;

const ContentDescription = styled.p`
  color: #c6cfd8;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
`;

export const SeasonOne = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate('/bonus-game');
  };
  return (
    <PageWrapper>
      <HorizontalView>
        <button
          onClick={handleGoBack}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <LeftArrowIcon color="rgba(82, 90, 103, 1)" width={24} height={24} />
        </button>
        <HeaderTitle>How it works?</HeaderTitle>
        <QuestionMarkIcon color="rgba(170, 222, 22, 1)" />
      </HorizontalView>
      <ContentWrapper>
        <ContentTitle>Welcome to Season One!</ContentTitle>
        <ContentDescription>
          Season 1: 60 days of competition Bigger Ranks, Bigger Rewards!
        </ContentDescription>
        <BonusPlacePrizes />

        <VerticalView style={{ gap: 16, marginTop: 32 }}>
          <BonusRuleRowItem
            title={'Random 10 members'}
            description={'Telegram Premium (3 months)'}
            icon={<StarIcon />}
          />
          <BonusRuleRowItem
            title={'Random 50 members'}
            description={'Merchandise to your location'}
            icon={<TShirtIcon />}
          />
          <BonusRuleRowItem
            title={'Top 200 members'}
            description={'Ambassador role on Haqabi Discord'}
            icon={<DiscordIcon />}
          />
          <BonusRuleRowItem
            title={'Top 1000 members'}
            description={'Get a Bazaar x Haqabi NFT'}
          />
        </VerticalView>
      </ContentWrapper>
    </PageWrapper>
  );
};
