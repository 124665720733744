import styled from 'styled-components';

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.span`
  font-size: 16px;
  color: white;
`;

const EditableInput = styled.input`
  font-size: 16px;
  color: white;
  background: transparent;
  border: none;
  border: 1px solid rgba(170, 222, 22, 1);
  outline: none;
  padding: 4px;
  width: 100%;
  max-width: 200px;
`;
const GameHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const QuestionMarkWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  LabelWrapper,
  Label,
  EditableInput,
  GameHeaderWrapper,
  QuestionMarkWrapper,
};
