import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';
import { HorizontalView } from 'src/components/FlexViews';
import { useNavigate } from 'react-router-dom';
import { useGetWeekRewards } from './hooks/useGetWeekRewards';

const RewardsWrapper = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const Title = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;

const RewardsRow = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  border-radius: 30px;
`;

const RewardAmount = styled.p`
  font-size: 10px;
  font-weight: 700;
  font-family: Public-Sans, sans-serif;
`;

const RewardCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border-right: 1px solid #14232f;

  &:first-child {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
  }

  &:last-child {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
`;

const activeColor = '#2E4F6C';
const inActiveColor = '#1F3245';

const dailyRewards = [
  {
    day: '1',
    coins: '100',
  },
  {
    day: '2',
    coins: '200',
  },
  {
    day: '3',
    coins: '300',
  },
  {
    day: '4',
    coins: '400',
  },
  {
    day: '5',
    coins: '500',
  },
];

export const DailyRewards = ({ daysInARow }) => {
  const navigate = useNavigate();

  return (
    <RewardsWrapper>
      <Title>Earn daily rewards</Title>
      <RewardsRow onClick={() => navigate('/bonus-game/daily-rewards')}>
        {dailyRewards.map(rewardItem => (
          <RewardCard
            key={rewardItem.day}
            style={{
              backgroundColor:
                Number(rewardItem.day) === Number(daysInARow) + 1
                  ? activeColor
                  : inActiveColor,
            }}>
            <p>
              Day {rewardItem.day}
              {rewardItem.day == '5' ? '+' : ''}
            </p>
            <HorizontalView style={{ gap: 2 }}>
              <CoinIcon width={10} height={10} />{' '}
              <RewardAmount>{rewardItem.coins}</RewardAmount>
            </HorizontalView>
          </RewardCard>
        ))}
      </RewardsRow>
    </RewardsWrapper>
  );
};
