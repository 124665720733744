import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';
import { ReactComponent as LeftArrowIcon } from 'src/assets/icons/bonus-game/left-arrow.svg';
import { DailyRewardItem } from './components/RewardItem';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { bonusGameApi } from 'src/api/bonus-game';

const DailyRewardsPageWrapper = styled(VerticalView)`
  justify-content: center;
  padding: 0 20px;
`;

const Title = styled.p`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

const HeaderWrapper = styled(HorizontalView)`
  ${Title} {
    margin: auto;
    position: relative;
    left: -12px;
  }
  margin-bottom: 32px;
`;

const DailyRewardsWrapper = styled(HorizontalView)`
  flex-wrap: wrap;
  gap: 2.051vw;
  justify-content: flex-start;

  @media (max-width: 360px) {
    justify-content: center;
    gap: 4vw;
  }
`;

export const DailyRewardsPage = () => {
  const navigate = useNavigate();
  const [dailyRewards, setDailyRewards] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const dailyRewardsRes = await bonusGameApi.getRewardsHistory();
        setDailyRewards(dailyRewardsRes.result);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <DailyRewardsPageWrapper>
      <HeaderWrapper>
        <button onClick={() => navigate('/bonus-game')}>
          <LeftArrowIcon color="rgba(82, 90, 103, 1)" width={24} height={24} />
        </button>
        <Title>Daily Rewards</Title>
      </HeaderWrapper>
      <DailyRewardsWrapper>
        {dailyRewards?.usersHistory?.map(rewardItem => (
          <DailyRewardItem
            key={dailyRewards.day}
            {...rewardItem}
            isCurrentDay={rewardItem.day === dailyRewards.currentDay}
          />
        ))}
      </DailyRewardsWrapper>
    </DailyRewardsPageWrapper>
  );
};
