import styled from 'styled-components';
import { Container } from '../../../../components/Container/styled';

export const Recovery = () => {
  return (
    <Container style={{ marginBottom: '12.8vw' }}>
      <TitleStyled>
        Secure wallet recovery with{' '}
        <span style={{ color: 'var(--color-primary-5)' }}>Haqabi</span>
      </TitleStyled>
      <RecoveryExplanation>
        <RecoveryExplanationTitle>Seamless Recovery</RecoveryExplanationTitle>
        <RecoveryExplanationText>
          Distributed key storage allows you to restore access to the wallet by
          securing 2 out of 3 parts of the key.
        </RecoveryExplanationText>
        <RecoveryExplanationText>
          You maintain full control of your private keys, as all parts of the
          generated key are stored in vaults that only you have access to,
          ensuring maximum security and privacy.
        </RecoveryExplanationText>
      </RecoveryExplanation>
    </Container>
  );
};

const TitleStyled = styled.p`
  font-weight: 600;
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  color: var(--color-neutral-1);
  text-align: center;
  margin-bottom: 6.4vw;
  text-transform: uppercase;
`;

const RecoveryExplanation = styled.div`
  background-color: var(--color-neutral-12);
  padding: 4.267vw;
  border: solid rgba(41, 49, 62, 1) 1px;
  border-radius: 2.667vw;
`;

const RecoveryExplanationTitle = styled.p`
  font-weight: 500;
  font-family: Inter, sans-serif;
  font-size: 4.8vw;
  margin-bottom: 3.2vw;
  color: var(--color-primary-5);
`;

const RecoveryExplanationText = styled.p`
  color: rgba(234, 237, 238, 1);
  font-family: Inter, sans-serif;
  font-size: 4.267vw;
  line-height: 5.867vw;
  &:nth-child(2) {
    margin-bottom: 4.267vw;
  }
`;
