import { Container, Wrapper } from 'src/components/Container/styled';
import { Footer } from 'src/pages/Main/components/Footer';
import { FooterDesktop } from 'src/pages/Main/components/FooterDesktop';
import { Header } from 'src/pages/Main/components/Header';
import { HeaderDesktop } from 'src/pages/Main/components/HeaderDesktop';
import { useDeviceType } from 'src/hooks/useDeviceType';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ScrollToTopIcon } from 'src/assets/icons/scroll.svg';
import {
  BottomDescription,
  DataItemPoint,
  DataItemPoints,
  DataItemStyled,
  DataItemTitle,
  DataWrapperStyled,
  DescriptionStyled,
  HaqabiWalletTeam,
  ListElement,
  ScrollToTop,
  TitleStyled,
  UpdateInfoStyled,
} from './styled';

const data = [
  {
    title: '1 Legal Scope',
    points: [
      // eslint-disable-next-line react/jsx-key
      `1.1. These terms and conditions (“Terms and Conditions”) apply to the
        contractual relationship between the HAQABI Wallet users (“Customer(s)”,
        “user” or “you”) and the HAQABI Wallet (“HAQABI” or “us”) with respect
        to the non-custodial HAQABI wallet (“Crypto Wallet”) which is accessible
        via the HAQABI Mobile App. The HAQABI Mobile App shall hereinafter be
        referred to as the “HAQABI Mobile App”.`,
      '1.2. The business relationship between the Customer and HAQABI is subject to HAQABI’s general terms and conditions(“HAQABI’s General T&Cs”).',
      '1.3. HAQABI does not give tax, investment, legal, or other professional advice by allowing you to use the Services, or any ancillary investment related advice. Prior to engaging in any transaction or investment activity, you should consult a qualified professional. You acknowledge that (a) you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for you; (b) you are familiar with the operation of Digital Assets and have the experience required to use the Services; and (c) you are responsible for determining whether using the Services is legal in your jurisdiction and you shall not use any of the Services if such use is deemed illegal.',
    ],
  },
  {
    title: '2. Defining a Non – Custodial Wallet',
    points: [
      '2.1. A non-custodial wallet can be described as one where only the primary user will have access to their private keys. In further, being non-custodial in nature the HAQABI operations team cannot operate, manage or process any data in relation to the primary’s user’s wallet. Therefore, when interacting with a non-custodial wallet, the primary user maintains and retains full responsibility for the security of their wallet. ',
    ],
  },
  {
    title: '3. User Interface',
    points: [
      `3.1. HAQABI offers the Customer the possibility of opening and administering a Crypto Wallet for the storage of different kinds of tokens based on Haqq, Tron, Ethereum and other blockchain networks. In the future, HAQABI Wallet is likely to support tokens based on other networks, like BNB Smart Chain. The Customer can initiate deposit, withdrawal, and transfer transactions with respect to cryptocurrency in the Crypto Wallets via the HAQABI Apps. Additionally, HAQABI Wallet provides the Customer with the option to perform swaps via a DApp named Axelar.
      The HAQABI Wallet is only capable of supporting certain Digital Assets, as designated by us. You may not attempt to store any Digital Assets in your Wallet which we do not support.
      HAQABI reserves the right to change or cancel the offered services or supplement them with additional services. In particular, HAQABI reserves the right to cease supporting Crypto Wallets. When changing or canceling offered services, HAQABI will consider the Customer’s legitimate interests while taking other relevant factors into account.
      `,
    ],
  },
  {
    title: '4. Use of Services – Accessibility',
    points: [
      `4.1. HAQABI Wallet grants the user a revocable, limited, nonexclusive, non-transferable license to access and use the Services. Your access and use of the Services is conditioned on your compliance with this Agreement.
      `,
    ],
  },
  {
    title: '5. KYC Onboarding and User Obligations',
    points: [
      `5.1. The user by registering to use a HAQABI Wallet account, represents and warrants that: (i) as an individual, you are at least 18 or are of legal age to form a binding contract under applicable laws; (ii) as an individual, legal person, or other organization, you have full legal capacity and sufficient authorizations to enter into these Terms; (iii) The user has not been previously suspended or removed from using either HAQABI Wallet or other reputable crypto wallet providers for legitimate reasons; (iv) The user is not resident, located in or otherwise attempting to access the HAQABI Wallet or its services from, or otherwise acting on behalf of a person or legal entity that is resident or located in, a Restricted Location. A restricted location would be defined on the basis and parameters set out by the national GPM of each country.
      `,
      `5.2. If the user is either or acts on behalf of an employee or agent of a legal entity, and enters into these Terms on their behalf, they represent and warrant that you have all the necessary rights and authorizations to bind such legal entity and to access and use the HAQABI Wallet and its services on behalf of such legal entity.`,
      `5.3. The user agrees that its use of either HAQABI Wallet or its services will not violate any and all laws and regulations applicable to you or the legal entity on whose behalf you are acting, including but not limited to regulations on anti-money laundering, anti-corruption, and counter-terrorist financing.`,
      `5.4. The user shall be responsible for informing itself about and observing any restrictions and/or requirements imposed with respect to the access to and use of the HAQABI Wallet in each country from which the HAQABI Wallet and its services are accessed by you or on your behalf. HAQABI Wallet reserves the right to change, modify or impose additional restrictions with respect to the access to and use of the HAQABI Wallet and its services from time to time at their discretion at any time without prior notification.`,
      `5.5. HAQABI Wallet assumes no liability for any loss or damage arising from the use of HAQABI Wallet Account by you or any third party with or without your authorization.`,
    ],
  },
  {
    title: '6. HAQABI Wallet account Registration Process',
    points: [
      `6.1. The registration process for a HAQABI Wallet account is designed to ensure robust security while being user-friendly. Here is how it works:`,
      'Phone Number Entry and Verification:',

      // eslint-disable-next-line react/jsx-key
      <ul>
        <ListElement>Start by entering your phone number.</ListElement>
        <ListElement>
          {`To confirm its ownership, you'll receive an SMS. Follow the
          instructions in the SMS to complete the verification process.`}
        </ListElement>
      </ul>,
      'Auth0 Service Permission and Social Network Authentication:',
      // eslint-disable-next-line react/jsx-key
      <ul>
        <ListElement>
          Grant permission to the Auth0 service. This is necessary for
          authentication using one of the selected social networks (Google,
          Facebook, Twitter, or Discord).
        </ListElement>
        <ListElement>
          {`Proceed to authenticate through your chosen social network account. During this step, the Auth0 service will return an authentication token along with the email or username associated with your social network account.`}
        </ListElement>
      </ul>,
      'Automatic Seed Phrase Generation and Storage:',
      // eslint-disable-next-line react/jsx-key
      <ul>
        <ListElement>
          Following your authentication, a seed phrase is automatically
          generated for your wallet. This process doesn’t require any additional
          actions from you.
        </ListElement>
        <ListElement>
          {`The seed phrase is then divided into three parts using the Shamir Secret Sharing algorithm, ensuring enhanced security.`}
        </ListElement>
        <ListElement>
          The first part, linked to your phone number authentication, is stored
          on the ours backend.
        </ListElement>
        <ListElement>
          The second part, associated with your social network authentication
          token, is kept in the custodial service named Hastody.
        </ListElement>
        <ListElement>
          The third part is saved either in Google Drive or iCloud, depending on
          whether you are using an Android or Apple device.
        </ListElement>
        <ListElement>
          All these parts are stored in secure repositories to safeguard your
          information.
        </ListElement>
        <ListElement>
          Additionally, an extra layer of cross-encryption is applied to further
          enhance the security of your wallet.
        </ListElement>
      </ul>,
    ],
  },
  {
    title: '7 HAQABI Wallet account Recovery Process ',
    points: [
      `7.1. You can recover access to your HAQABI Wallet account using the phone number you used for the account registration. If you have lost access to your phone number, HAQABI does not guarantee the ability to assist in recovering your account. If you have lost access to both the Google/iCloud account on your phone and the social network account linked to your HAQABI Wallet, recovery of your account is unfortunately impossible according to our security protocols aimed at safeguarding users’ accounts against unauthorized access.`,
      `Here is how recovery process works:`,
      `Phone Number Confirmation:`,
      // eslint-disable-next-line react/jsx-key
      <ul>
        <ListElement>
          Begin by entering the phone number you used during the registration
          process.
        </ListElement>
        <ListElement>
          Confirm your phone number through an SMS verification process.
        </ListElement>
        <ListElement>
          Your phone number is a crucial component for account recovery. If you
          have lost access to your phone number, HAQABI does not guarantee the
          ability to assist in recovering your account.
        </ListElement>
      </ul>,
      'Searching for the Cloud-Linked Seed Phrase Part:',
      // eslint-disable-next-line react/jsx-key
      <ul>
        <ListElement>
          The system will automatically search for the part of the seed phrase
          linked to the cloud storage on your device (either Google Drive or
          iCloud).
        </ListElement>
        <ListElement>
          If this part of the seed phrase is successfully located, the account
          recovery process is complete, and you regain access to your wallet.
        </ListElement>
      </ul>,
      'Social Network Authentication as an Alternative:',
      // eslint-disable-next-line react/jsx-key
      <ul>
        <ListElement>
          In case the cloud-linked seed phrase part is not found, the next step
          is to log in through the social network associated with your account.
        </ListElement>
        <ListElement>
          Authenticate successfully via the social network using the auth0
          service. Upon successful login, the account recovery process is also
          completed.
        </ListElement>{' '}
      </ul>,
      `
      Please note that If you are using a new device that has not been previously used to access your account, there is a mandatory 48-hour security waiting period. After 48 hours, you will need to confirm your phone number again. This step is crucial for ensuring the security of your account on a new device.`,
    ],
  },
  {
    title: '8. Intellectual Property',
    points: [
      `8.1. Provided that the HAQABI Wallet user agrees and complies with the present Terms, HAQABI Wallet shall grant them a non-exclusive, non-sublicensable and non-transferable license to use the HAQABI Wallet for their personal use only. Except as otherwise expressly permitted in these Terms, the user shall not: (a) reproduce, modify, adapt or create derivative works of any part of HAQABI Wallet; (b) rent, lease, distribute, sell, sublicense, transfer, or provide access to HAQABI Wallet; (c) use HAQABI Wallet for the benefit of any third party.
      The user also agrees not to incorporate HAQABI Wallet into a product or service they provide to a third party without HAQABI Wallets prior written consent. The user must also not circumvent mechanisms in HAQABI Wallet intended to limit your use nor reverse engineer, disassemble, decompile, translate, or otherwise seek to obtain or derive the source code, including images and texts, underlying ideas, algorithms, file formats or non-public APIs to HAQABI Wallet, except to the extent expressly permitted by applicable law (and then only upon advance notice to us); (g) remove or obscure any proprietary or other notices contained in HAQABI Wallet (h) use HAQABI Wallet for competitive analysis.
      `,
    ],
  },
  {
    title: '9. Representations and Warranties',
    points: [
      `9.1. The user by way of agreeing to these terms of and conditions agrees that all its representations and warranties made in relation to this agreement are both accurate and verifiable. The user also accepts and acknowledges that HAQABI Wallet to remove the Wallet from liability caused as a result of the errors and omissions by the user.
      HAQABI Wallet shall not be liable for any damages caused in whole or in part by (a) the malfunction, unexpected function or unintended function of any computer or cryptocurrency network, including without limitation losses associated with viruses, network forks, replay attacks, double-spend attacks, sybil attacks, 51% attacks, governance disputes, mining difficulty, changes in cryptography or consensus rules, Smart Contract hacks or breaches .; (b) the change in value of any cryptocurrency; (c) any change in law, regulation or policy, or (d) force majeure event (including but not limited to (I) acts of god, nature, court or government; (ii) failure or interruption in public or private telecommunication networks, communication channels or information systems; (iii) acts or omissions of acts of a party for whom HAQABI Wallet would not be responsible; (iv) delay, failure, or interruption in, or unavailability of, third party services and sites; (v) strikes, lock-outs, labor disputes, wars, terrorist acts and riots; and (vi) viruses, malwares, other malicious computer codes or the hacking of HAQABI’s systems).
      `,
      '9.2. The HAQABI Wallet is provided as marketed and without any warranty of any kind. To the maximum extent permitted by law, HAQABI Wallet disclaims all representations and warranties, express or implied, relating to the services and underlying software or any aspect of the information, content, or the services, whether provided or owned by us, or by any third party or contained in any third party materials or on any third party websites accessible or linked to our website or the services, including without limitation, warranties of merchantability, fitness for a particular purpose, title, non-infringement, freedom from computer virus, and any implied warranties arising from course of dealing, course of performance, or usage in trade, all of which are expressly disclaimed. In addition, we do not represent or warrant that the content accessible via the services is accurate, complete, available, current, free of viruses or other harmful components, or that the results of using the services will meet your requirements.',
      `9.3. The user acknowledges and agrees that HAQABI Wallet would have no control over, and no duty to take any action regarding: (i) failures, disruptions, errors, or delays in the processing of Digital Assets that you may experience while using the Services; (ii) the risk of failure of hardware, software, and Internet connections; (iii) the risk of malicious software being introduced or found in the software underlying HAQABI Wallet; (iv) the risk that third parties may obtain unauthorized access to information stored within your wallet, including, but not limited to your wallet access details; and (v) the risk of unknown vulnerabilities in or unanticipated changes to the applicable blockchain networks.
   `,
      `9.4. The user releases the HAQABI Wallet from all liability related to any losses, damages, or claims arising from: (a) user error such as forgotten passwords, incorrectly constructed transactions, or mistyped Digital Asset addresses; (b) server failure or data loss; (c) unauthorized access to the HAQABI Wallet application; (d) bugs or other errors in the HAQABI Wallet software; and (e) any unauthorized third party activities, including, but not limited to, the use of viruses, phishing, brute forcing, or other forms of cyber security vulnerabilities.`,
      `9.5. The HAQABI Wallet makes no representations concerning any Third Party Content contained in or accessed through our Services. Any other terms, conditions, warranties, or representations associated with such content, are solely between you and such organizations and/or individuals.`,
    ],
  },
  {
    title: '10. Changes to these Terms and Conditions',
    points: [
      '10.1. HAQABI Wallet reserves the right to alter, revise, modify, and/or change these Terms at its discretion at any time. HAQABI will notify of such revisions by publishing the updated Terms and Conditions. All changes will take effect immediately upon being published. To this note, a user that is in continuing use of the platform is recognized to have accepted the changes by default. If a specific user does not agree with the revisions or amendments, they must stop using the HAQABI application, as their continued use would be considered as deemed acceptance. It is your responsibility to regularly check relevant pages on our application to confirm the latest version of these Terms. If you do not agree to any such modifications, your only remedy is to terminate your usage of HAQABI Wallet Services and cancel your account.',
    ],
  },
  {
    title: '11. Limitation Period for Raising Claims',
    points: [
      `11.1. The user agrees that any claim you may have arising out of or related to your relationship with us must be filed within one year after such claim arises, otherwise, your claim is permanently barred.`,
    ],
  },
  {
    title: `12. Severability`,
    points: [
      `12.1. If individual provisions of the contract between HAQABI and the Customer should be or become legally invalid, the remaining provisions shall remain binding. The invalid parts are to be replaced by the corresponding statutory provisions to the extent that these exist.`,
    ],
  },
  {
    title: `13. Miscellaneous`,
    points: [
      `13.1. Independent Party Clause: HAQABI Wallet functions as an independent contractor and not as an agent of the user in their reliance of the aforementioned terms.
  `,
      `13.2. Assignment: The user may not assign or transfer any right to use the HAQABI Wallet Wallet or any of the users rights and obligations in relation to the concerned account, unless HAQABI Wallet provides an explicit consent in written form, including any right or obligation related to the enforcement of laws or the change of control. HAQABI Wallet may assign or transfer any or all of its rights or obligations under these Terms, in whole or in part, without notice or obtaining your consent or approval.`,
      `13.3. Third-Party Website Disclaimer. Any links to third-party websites from HAQABI Wallets does not imply an endorsement by HAQABI Wallet of any related/non-related product, service, information or disclaimer presented therein, nor does HAQABI Wallet guarantee the accuracy of the information contained on them. In the event the user suffers a loss as a result of reliance on the third-party product and service, HAQABI Wallet will not be liable for such loss.
   The third-party content shared on our Wallet is solely for informational purposes. HAQABI Wallet disclaims any liability related to the accuracy, completeness, legality, reliability, or availability of the information in these third-party articles.
   While we strive to share credible and reliable information, we encourage readers to critically evaluate and verify these third-party posts independently, as we cannot guarantee their complete accuracy or reliability. By accessing the HAQABI Wallet newsfeed, you know you acknowledge this disclaimer and agree that you understand we do not endorse or assume responsibility for third-party content.
   `,
    ],
  },
  {
    title: `14. Force Majeure`,
    points: [
      `14.1. HAQABI Wallet shall not be held liable for any delay or failure to perform as required by these Terms because of any cause or condition beyond HAQABI reasonable control. Likewise, HAQABI Wallet shall not be held liable for any delays, failure in performance, or interruptions of service which result directly or indirectly from any cause or condition beyond our reasonable control, including but not limited to: any delay or failure due to any acts of God, acts of civil or military authorities, acts of terrorism, civil or industrial disturbances, blockages, embargoes, war, strikes or other labor disputes, fire, earthquakes, storms or other nature-related events, interruption in electrical telecommunications or Internet services or network provider services, failure of hardware equipment and/or software or other utility failures, smart contract bugs or weaknesses, technological changes, changes in interest rates or other monetary conditions, and, for the avoidance of doubt, changes to any blockchain-related protocol, other catastrophe, or any other occurrences which are beyond our reasonable control, and shall not affect the validity and enforceability of any remaining provisions.
  `,
    ],
  },
  {
    title: `15. Termination and Service Discontinuation`,
    points: [
      `15.1. HAQABI Wallet if it deems necessary, in its sole discretion and without cost to you, with or without prior notice, and at any time, modify or discontinue, temporarily or permanently, any portion of our Services. HAQABI Wallet shall not be held responsible or liable for any loss of Digital Assets in the event that we discontinue all or any part of the Services.
  `,
      `15.2. The end user is solely responsible for ensuring the security of their phone number and social media accounts. If a third party accesses the user's phone number or social media account and subsequently logs into the user's HAQABI Wallet account, HAQABI is not liable for any resulting issues or damages.
  `,
      `15.3. HAQABI’s failure to exercise or delay in exercising any right, power, or privilege under this Agreement shall not operate as a waiver; nor shall any single or partial exercise of any right, power, or privilege preclude any other or further exercise thereof. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of us. Except as expressly set forth in this Agreement, the exercise by either party of any of its remedies under this Agreement will be without prejudice to its other remedies under this Agreement or otherwise.
  `,
    ],
  },
  {
    title: `16. Applicable Law and Legal Jurisdiction`,
    points: [
      `16.1. Any dispute between you and the HAQABI Wallet arising out of or relating to our products, services, this Agreement, or our relationship as created by this Agreement (whether arising out of contract, tort, statute, or any other manner) shall be settled through mediation. If a dispute is not settled through mediation, it shall be resolved through arbitration in accordance with the applicable rules and regulations.`,
    ],
  },
  {
    title: `17. Entire Agreement Clause`,
    points: [
      `17.1. This document containing the terms and conditions of application use sets forth the entire understanding and agreement as to the subject matter hereof and supersedes any and all prior discussions, agreements, and understandings of any kind (including, without limitation, any prior versions of the Terms) and every nature between us. Except as provided for above, any modification to these Terms must be in writing and must be signed by both parties.`,
    ],
  },
];

function TermsAndUseApp() {
  const { isMobile } = useDeviceType();
  const [scrollVisible, setScrollVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      if (isMobile && window.scrollY > (windowHeight / 100) * 5) {
        setScrollVisible(true);
      } else {
        setScrollVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  return (
    <Wrapper>
      {scrollVisible && (
        <ScrollToTop onClick={() => window.scrollTo(0, 0)}>
          <ScrollToTopIcon width={'12.267vw'} height={'12.267vw'} />
        </ScrollToTop>
      )}
      <div style={{ flex: 1 }}>
        {isMobile ? <Header isMain /> : <HeaderDesktop isMain={true} />}
        <Container>
          <TitleStyled>
            User Terms and Conditions – HAQABI Non-Custodial Wallet
          </TitleStyled>
          <UpdateInfoStyled>
            These terms and conditions govern the relationship between HAQABI
            Wallet and the end user of the HAQABI Wallet. The terms and
            conditions within this document cover topics and areas of law that
            are tangential and directly related to the services being provided
            though the HAQABI Wallet.
          </UpdateInfoStyled>
          <DescriptionStyled>
            By accessing, using, or attempting to use HAQABI Wallet, either
            directly, indirectly, or impliedly in any capacity, you acknowledge
            that you accept and agree to be bound by these Terms.
          </DescriptionStyled>
          <DescriptionStyled>
            Please read the terms carefully as they govern your use of the
            HAQABI Wallet.
          </DescriptionStyled>
          <DescriptionStyled>
            THESE TERMS CONTAIN PROVISIONS THAT ARE CENTRAL TO YOUR RELATIONSHIP
            WITH THE HAQABI WALLET, AS AN END USER.
          </DescriptionStyled>
          <DescriptionStyled>
            Table of Contents:
            <ul>
              <li>1. Legal Scope</li>
              <li>2. Defining a Non-Custodial Wallet</li>
              <li>3. User Interface</li>
              <li>4. Use of Services – Accessibility</li>
              <li>6. HAQABI Wallet account Registration Process</li>
              <li>7. HAQABI Wallet account Recovery Process</li>
              <li>8. Intellectual Property</li>
              <li>9. Representations and Warranties</li>
              <li>10. Changes to these Terms and Conditions</li>
              <li>10. Changes to these Terms and Conditions</li>
              <li>11. Limitation Period for Raising Claims</li>
              <li>12. Severability</li>
              <li>13. Miscellaneous</li>
              <li>14. Force Majeure</li>
              <li>15. Termination and Service Discontinuation</li>
              <li>16. Applicable Law and Legal Jurisdiction</li>
              <li>17. Entire Agreement Clause</li>
            </ul>
          </DescriptionStyled>
          <DataWrapperStyled>
            {data.map(item => (
              <DataItemStyled key={item.title}>
                <DataItemTitle>{item.title}</DataItemTitle>
                <DataItemPoints>
                  {item.points.map(point => (
                    <DataItemPoint key={point}>{point}</DataItemPoint>
                  ))}
                </DataItemPoints>
              </DataItemStyled>
            ))}
          </DataWrapperStyled>
          <BottomDescription>
            Thank you for choosing Haqabi Wallet. We look forward to providing
            you with a secure and user-friendly crypto experience!
          </BottomDescription>
        </Container>
      </div>
      {isMobile ? <Footer isMain /> : <FooterDesktop isMain />}
    </Wrapper>
  );
}

export default TermsAndUseApp;
