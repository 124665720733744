import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import styled from 'styled-components';
import { ReactComponent as TShirtIcon } from 'src/assets/icons/bonus-game/t-shirt.svg';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';

const getBorderColorByRow = row => {
  switch (row) {
    case 1:
      return '#84C552';
    case 2:
      return '#8F782B';
    case 3:
      return '#5688AC';
  }
};

const getBgByRow = row => {
  switch (row) {
    case 1:
      return 'linear-gradient(90deg, #7EF424 0%, #C8EB01 100%)';
    case 2:
      return 'linear-gradient(90deg, #FEBF07 0%, #EB9201 100%)';
    case 3:
      return 'linear-gradient(90deg, #1F92E4 0%, #1DC0E4 100%)';
  }
};

const BonusPlacePrizeWrapper = styled(VerticalView)`
  position: relative;
  border-radius: 8px;
  border: 1px solid ${({ row }) => getBorderColorByRow(row)};
  background: #21252e;
  width: 20.513vw;
  height: 20.513vw;
`;
const BonusInfo = styled(VerticalView)`
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 4px;
`;

const BonusAmount = styled.p`
  font-size: 16px;
  color: #fffefd;
  font-family: Public-Sans, sans-serif;
`;

const BonusPlaces = styled(VerticalView)`
  border-radius: 0px 0px 6px 6px;
  background: ${({ row }) => getBgByRow(row)};
  height: 16.25px;
  display: grid;
`;

const BonusPlaceText = styled.p`
  color: #05143c;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 12px;
  font-weight: 500;
  align-self: center;
  justify-self: center;
`;

export const BonusPlacePrize = ({ row, place, prize }) => {
  return (
    <BonusPlacePrizeWrapper row={row}>
      <BonusInfo>
        {row === 1 ? (
          <HorizontalView style={{ fontSize: 20, padding: 6, gap: 4 }}>
            <p style={{ fontSize: 20 }}>💰</p>
            +
            <TShirtIcon />
          </HorizontalView>
        ) : (
          <CoinIcon />
        )}

        <BonusAmount>{prize}</BonusAmount>
      </BonusInfo>
      <BonusPlaces row={row}>
        <BonusPlaceText>{place}</BonusPlaceText>
      </BonusPlaces>
    </BonusPlacePrizeWrapper>
  );
};
