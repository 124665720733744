export function increaseNumberAnimation(
  elementId,
  endNumber,
  currentNumber = 0,
  speed = 10,
  addBy = 1,
  setCurrentBalance,
) {
  const element = document.getElementById(elementId);

  if (!element) return;

  // const animationRunning = JSON.parse(
  //   element.dataset.animationRunning ?? false,
  // );

  // if (animationRunning) return;

  element.dataset.animationRunning = true;
  incNbrRec(currentNumber, endNumber, element, speed, addBy, setCurrentBalance);
  return element.innerHTML;
}

function incNbrRec(
  currentNumber,
  endNumber,
  element,
  speed,
  addBy,
  setCurrentBalance,
) {
  if (currentNumber <= endNumber) {
    setCurrentBalance(currentNumber);
    setTimeout(function () {
      incNbrRec(
        currentNumber + addBy,
        endNumber,
        element,
        speed,
        addBy,
        setCurrentBalance,
      );
    }, speed);
  } else {
    element.dataset.animationRunning = false;
  }
}
