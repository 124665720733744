import { useLayoutEffect } from 'react';
import { HorizontalView, VerticalView } from 'src/components/FlexViews';
import { Button } from 'src/pages/BonusGame/components/Button';
import styled from 'styled-components';
import { ReactComponent as CoinIcon } from 'src/assets/icons/bonus-game/coin.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-menu.svg';
import { useNavigate } from 'react-router-dom';
import { bonusGameApi } from 'src/api/bonus-game';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalWrapper = styled(VerticalView)`
  position: relative;
  width: 100%;
  padding: 32px 24px;
  border-radius: 12px;
  border: 1px solid #29313e;
  background: #171d25;
`;
const Title = styled.p`
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(123deg, #03b8ff 14.45%, #fa52a0 76.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
`;

const GameRule = styled.p`
  color: #fcfcfc;
  text-align: center;
  font-family: Public-Sans, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const ButtonStyled = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
`;
const BonusAmount = styled.p`
  color: #fffefd;
  font-family: Public-Sans, sans-serif;
  font-size: 24px;
  font-weight: 500;
`;

export const WelcomeBonusModal = ({ setShowModal }) => {
  const navigate = useNavigate();

  async function handleClaimReward() {
    await bonusGameApi.getWelcomeBonus();
    navigate('/bonus-game');
  }

  return (
    <ModalOverlay>
      <div style={{ padding: '0 20px' }}>
        <ModalWrapper>
          <ButtonStyled onClick={() => setShowModal(false)}>
            <CloseIcon color="rgba(41, 49, 62, 1)" />
          </ButtonStyled>
          <Title>Welcome bonus!</Title>
          <p style={{ fontSize: 32, alignSelf: 'center', marginBottom: 20 }}>
            🏆
          </p>
          <GameRule>You’ve earned 1,000 points!</GameRule>
          <GameRule>Keep collecting and climb the leaderboard</GameRule>
          <HorizontalView
            style={{ gap: 8, alignSelf: 'center', marginBottom: 32 }}>
            <CoinIcon />
            <BonusAmount>1000</BonusAmount>
          </HorizontalView>
          <Button
            title={'Claim reward'}
            color={'positive'}
            onClick={handleClaimReward}
          />
        </ModalWrapper>
      </div>
    </ModalOverlay>
  );
};
