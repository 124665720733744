import styled from 'styled-components';
import { Container } from '../../../../components/Container/styled';

export const Protection = () => {
  return (
    <Container style={{ marginBottom: '12.8vw' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6.4vw',
        }}>
        <TitleStyled>Empowering Ethical Investing</TitleStyled>
        <DescriptionStyled>
          {' '}
          Building trust and inclusivity in the crypto world through fraud
          protection and support for Halal tokens
        </DescriptionStyled>
        <ProtectionBlock>
          <BlockTitle>Fraud Protection</BlockTitle>
          <BlockDescription>
            Haqabi tracks fraudulent transactions and provides users with token
            ratings, as well as price analytics. This helps users make informed
            decisions and avoid fraudulent schemes
          </BlockDescription>
        </ProtectionBlock>
        <ProtectionBlock>
          <BlockTitle>Support for Halal Tokens</BlockTitle>
          <BlockDescription>
            Considering the cultural and religious characteristics of residents
            in developing countries, Haqabi introduces a special badge in the
            application that highlights halal tokens
          </BlockDescription>
        </ProtectionBlock>
      </div>
    </Container>
  );
};

const TitleStyled = styled.p`
  color: var(--color-nautral-1);
  font-family: Inter, sans-serif;
  font-size: 5.333vw;
  font-weight: 600;
  text-align: center;
`;

const DescriptionStyled = styled.p`
  font-family: Inter, sans-serif;
  color: var(--color-nautral-3);
  text-align: center;
  font-size: 3.2vw;
  line-height: 4.48vw;
`;

const ProtectionBlock = styled.div`
  background-color: var(--color-neutral-12);
  padding: 4.267vw;
  border-radius: 2.667vw;
`;

const BlockTitle = styled.p`
  color: var(--color-primary-5);
  font-size: 4.8vw;
  font-family: Inter, sans-serif;
  font-weight: 500;
  margin-bottom: 3.2vw;
`;

const BlockDescription = styled.p`
  font-size: 4.267vw;
  line-height: 5.867vw;
  color: rgba(234, 237, 238, 1);
  font-family: Inter, sans-serif;
`;
