import styled from 'styled-components';

const DailyBonusItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DailyBonusItemWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  text-align: center;
  background-color: #171d25;
  border: 1px solid #29313e;
  border-radius: 10px;
  padding: 8px 20px;

  @media (max-width: 380px) {
    padding: 8px 10px;
  }
`;
const DailyBonusItemTitle = styled.p`
  font-size: 11px;
  color: ${({ color }) => color};
  font-weight: 500;
  font-family: Roboto, sans-serif;
`;
const DailyBonusItemText = styled.p`
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  font-family: Public-Sans, sans-serif;
  font-size: 11px;
  font-weight: 600;
`;

export {
  DailyBonusItemsWrapper,
  DailyBonusItemWrapper,
  DailyBonusItemTitle,
  DailyBonusItemText,
};
