import { Header } from '../Main/components/Header';
import { HeaderDesktop } from '../Main/components/HeaderDesktop';
import { useDeviceType } from 'src/hooks/useDeviceType';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';
import { useRef, useState } from 'react';
import {
  ArrowIconStyled,
  Container,
  DeleteAccountAccordionWrapper,
  DeleteAccountBody,
  DeleteAccountBodyCollapse,
  InfoTextFirstStyled,
  InfoTextStyled,
  ListStyled,
  OrderListStyled,
  TitleStyled,
  Wrapper,
} from './styled';

export const DeleteAccount = () => {
  const { isMobile } = useDeviceType();
  const itemRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const iconSize = {
    width: isMobile ? '6.4vw' : '1.667vw',
    height: isMobile ? '6.4vw' : '1.667vw',
  };

  return (
    <Wrapper>
      {isMobile ? <Header /> : <HeaderDesktop />}
      <Container>
        <DeleteAccountAccordionWrapper>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => setIsOpen(!isOpen)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TrashIcon {...iconSize} />
              <TitleStyled>How to delete account?</TitleStyled>
            </div>
            <ArrowIconStyled $isOpen={isOpen}>
              <ArrowIcon
                {...iconSize}
                color={isOpen ? '#AADE16' : 'white'}
                opacity={isOpen ? '1' : '0.5'}
              />
            </ArrowIconStyled>
          </div>
          <DeleteAccountContent isOpen={isOpen} />
        </DeleteAccountAccordionWrapper>
      </Container>
    </Wrapper>
  );
};

const DeleteAccountContent = ({ isOpen }) => {
  const itemRef = useRef(null);
  const { isMobile } = useDeviceType();
  const marginTop = isMobile ? '6.4vw' : '1.667vw';
  return (
    <DeleteAccountBodyCollapse
      style={{
        height: isOpen ? itemRef.current.scrollHeight : 0,
      }}>
      <DeleteAccountBody ref={itemRef}>
        <InfoTextFirstStyled style={{ marginTop }}>
          To ensure a comprehensive understanding and facilitate a seamless
          process for the deletion of your account within the Haqabi mobile
          application, a cryptocurrency wallet, please adhere to the following
          detailed instructions. This procedure is critical for users who wish
          to permanently remove their account and associated personal data from
          our systems. Note that while personal data will be erased, blockchain
          addresses and transactions will be preserved for record-keeping and
          compliance purposes.{' '}
        </InfoTextFirstStyled>
        <InfoTextStyled style={{ marginTop }}>
          Follow these steps meticulously:
        </InfoTextStyled>
        <OrderListStyled>
          <InfoTextStyled style={{ marginTop }}>
            {`1. Navigate to Settings: Begin by opening the Haqabi mobile
          application. Access the main menu and proceed to the 'Settings'
          option. Within Settings, locate the section titled 'Dangerous
          actions'. This section houses options that can significantly alter
          your account status or personal data.`}
          </InfoTextStyled>
          <InfoTextStyled>
            {`2. Initiate Account Deletion: Within the 'Dangerous actions' menu, you
          will find the 'Delete account' button. Select this option to initiate
          the process of permanently deleting your account. Please proceed with
          caution, as this action is irreversible.`}
          </InfoTextStyled>
          <InfoTextStyled>
            {`3. Confirm Account Deletion: After selecting the 'Delete account' option, you will be prompted to confirm your decision. To do so, tap and hold the 'Confirm delete' button. This step is designed to prevent accidental deletions and ensure that you are fully aware of the action being taken.`}
          </InfoTextStyled>
          <InfoTextStyled>
            {`4. Secure Your Seed Phrase: Before the deletion process can be completed, you will be required to write down or copy your seed phrase. The seed phrase is a crucial security feature that allows for the recovery of your account should you choose to reinstate it in the future. Ensure that this seed phrase is stored securely and accessible only to you.`}
          </InfoTextStyled>
          <InfoTextStyled>
            {`5. Acknowledge Seed Phrase Storage: Once you have securely saved your seed phrase, tap the 'I saved seed phrase' button to acknowledge that you have taken the necessary steps to secure it. This acknowledgment is a mandatory step in the account deletion process.`}
          </InfoTextStyled>
          <InfoTextStyled>{`6. Completion of Account Deletion: Following the acknowledgment of your seed phrase storage, the account deletion process is complete. At this point, your Haqabi account has been permanently removed, and you will no longer have access to it.`}</InfoTextStyled>
        </OrderListStyled>
        <InfoTextStyled style={{ marginTop }}>
          <span style={{ fontWeight: 600 }}>
            Data Deletion and Retention Policy:
          </span>
        </InfoTextStyled>
        <InfoTextStyled style={{ marginTop }}>
          Upon the completion of your account deletion, we will proceed with the
          removal of all personal data associated with your account from our
          systems. This includes, but is not limited to:
        </InfoTextStyled>
        <ListStyled>
          <li>
            <InfoTextStyled
              style={{ marginTop: isMobile ? '2.133vw' : '0.556vw' }}>
              Your phone number.
            </InfoTextStyled>
          </li>
          <li>
            <InfoTextStyled>
              Your email address or any nicknames used in social media
              platforms.
            </InfoTextStyled>
          </li>
          <li>
            <InfoTextStyled>
              Your country of residence and IP addresses.
            </InfoTextStyled>
          </li>
        </ListStyled>

        <InfoTextStyled style={{ marginTop }}>
          Please be informed that while your personal data will be deleted, we
          are obligated to retain records of your blockchain addresses and
          transactions. This retention is necessary for compliance with
          regulatory requirements and for maintaining the integrity of the
          blockchain network.
        </InfoTextStyled>
        <InfoTextStyled style={{ marginTop }}>
          We appreciate your use of the Haqabi mobile application and respect
          your decision to delete your account. Should you have any further
          questions or require assistance during this process, do not hesitate
          to contact our support team by{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 600 }}>
            support@haqqex.com
          </span>{' '}
          for guidance.
        </InfoTextStyled>
      </DeleteAccountBody>
    </DeleteAccountBodyCollapse>
  );
};
