import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import images from './util/images';
import s from './index.module.scss';
import { fetchData } from 'src/butter-client';
import messages from './util/messages';
import { useBreakpoint } from 'src/hooks/useBreakpoint';
import { Text } from 'src/pages/CMS/components';
import Image from 'src/pages/CMS/components/Image';
import { Article } from './components/Article';
import Loader from 'src/pages/CMS/components/Loader';
import { Container } from '../../../../components/Container/styled';
import styled from 'styled-components';

const Divider = styled.div`
  width: 100%;
  height: 0.267vw;
  background: linear-gradient(#c6cfd866, #c6cfd8, #c6cfd866);
  opacity: 0.2;
  margin: 8vw 0;

  @media (min-width: 769px) {
    height: 0.069vw;
    margin: 8.472vw 0;
  }
`;

export const Education = () => {
  const { md, lg } = useBreakpoint();
  const [showLoader, setShowLoader] = useState(true);
  const [swiper, setSwiper] = useState();
  const [articles, setArticles] = useState([]);
  const [currSlide, setCurrSlide] = useState(swiper?.activeIndex || 0);

  const next = useCallback(() => {
    swiper && swiper.slideNext();
  }, [swiper]);

  const prev = useCallback(() => {
    swiper && swiper.slidePrev();
  }, [swiper]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchData('posts', {
          category_slug: 'haqabi',
        });
        setArticles(response.data);
      } catch (e) {
        console.error(e);
      } finally {
        setShowLoader(false);
      }
    })();
  }, []);

  return (
    <Container>
      <Divider />
      <div id="Education" className={cx('relative z-1')}>
        <Text
          className={lg ? 'text-center' : ''}
          size="b32"
          message={messages.education.en}
        />
        {showLoader ? (
          <Loader small />
        ) : (
          <>
            {lg ? (
              <div className="relative mt-64">
                <Swiper
                  style={{ width: 'calc(100% - 15rem)' }}
                  onSwiper={setSwiper}
                  onSlideChange={swiper => setCurrSlide(swiper.activeIndex)}
                  spaceBetween={20}
                  slidesPerView={3}
                  grabCursor>
                  {articles.slice(0, 6).map(item => (
                    <SwiperSlide key={item.slug} className="h-auto">
                      <Article
                        url={`/education${item.url}`}
                        title={item.title}
                        description={item.summary}
                        image={item.featured_image}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="absolute w-full top-50 flex items-center justify-between">
                  <button
                    type="button"
                    onClick={prev}
                    disabled={currSlide === 0}>
                    <Image
                      size={32}
                      src={images.arrowLeft}
                      color={currSlide === 0 ? 'neutral-6' : 'primary-5'}
                    />
                  </button>
                  <button type="button" onClick={next} disabled={swiper?.isEnd}>
                    <Image
                      size={32}
                      src={images.arrowRight}
                      color={swiper?.isEnd ? 'neutral-6' : 'primary-5'}
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div
                className={cx(
                  'relative flex',
                  md
                    ? 'mt-40 flex-row grid-gap-16'
                    : 'mt-48 flex-col grid-gap-24',
                )}>
                {articles.slice(0, 2).map(item => (
                  <Article
                    key={item.slug}
                    url={`/education${item.url}`}
                    title={item.title}
                    description={item.summary}
                    image={item.featured_image}
                  />
                ))}
              </div>
            )}
          </>
        )}
        <a
          href={`/education`}
          className={cx(
            'bg-primary-5 flex  text-center radius-8 py-8 px-24 justify-center',
            {
              [cx(s.button, 'mt-32 w-fit-content')]: md && !lg,
              'ml-auto mr-auto mt-12 w-fit-content': lg,
              'w-full mt-32': !md,
            },
          )}>
          <Text size="m16" message={messages.showAll.en} color="neutral-10" />
        </a>
      </div>
    </Container>
  );
};
