import styled from 'styled-components';
import { Container } from '../../../../components/Container/styled';
import SwapsImage from 'src/assets/img/swaps.webp';
import {
  ContentContainerStyled,
  ContentDescriptionStyled,
  ContentTitleStyled,
  ContentWrapperStyled,
  ImageStyled,
} from './styled';
import recoveyMockup from 'src/assets/img/recovery-mockup.webp';

export const RecoveryDesktop = () => {
  return (
    <Container style={{ marginBottom: '8.333vw' }}>
      <ContentWrapperStyled>
        <ContentContainerStyled>
          <ContentTitleStyled>
            Secure wallet recovery with Haqabi
          </ContentTitleStyled>
          <ContentDescriptionStyled
            style={{
              color: '#AADE16',
              fontWeight: '500',
              fontSize: '1.736vw',
              marginBottom: '2.222vw',
            }}>
            Seamless Recovery
          </ContentDescriptionStyled>
          <ContentDescriptionStyled>
            Distributed key storage allows you to restore access to the wallet
            by securing 2 out of 3 parts of the key.
          </ContentDescriptionStyled>
          <ContentDescriptionStyled>
            You maintain full control of your private keys, as all parts of the
            generated key are stored in vaults that only you have access to,
            ensuring maximum security and privacy.
          </ContentDescriptionStyled>
        </ContentContainerStyled>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%',
            paddingLeft: '3.8vw',
            flex: 1,
          }}>
          <ImageStyled alt="rocovery-mockup" src={recoveyMockup} />
        </div>
      </ContentWrapperStyled>
    </Container>
  );
};

const TitleStyled = styled.p`
  font-size: Inter, sans-serif;
  font-weight: 700;
  font-size: 2.917vw;
  text-align: center;
  margin-bottom: 2.778vw;
`;

const RecoveryDesktopWrapper = styled.div`
  background: linear-gradient(
    104.69deg,
    rgba(255, 255, 255, 0.05) 15.53%,
    rgba(255, 255, 255, 0) 87.71%
  );
  border: solid rgba(82, 90, 103, 1) 0.069vw;
  border-radius: 0.833vw;
  padding: 0;
`;
