import styled from 'styled-components';

function getColor(color) {
  switch (color) {
    case 'positive':
      return 'rgba(170, 222, 22, 1)';

    default:
      return 'rgba(255, 255, 255, 1)';
  }
}

export const ButtonStyled = styled.button`
  border-radius: 8px;
  background: #29313e;
  display: flex;
  height: 52px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => getColor(color)};
  font-size: 14px;
  font-family: Public-Sans, sans-serif;
  line-height: 20px;
  user-select: none;
`;
